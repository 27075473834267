.App {
  text-align: center;
}
.MuiTypography-body1 {
  font-weight: unset !important;
  font-size: unset !important;
}

.marker {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.leaflet-container {
  height: inherit;
  width: 100%;
}

.text-area-field {
  font: inherit;
  font-weight: 500;
  font-size: 1rem;
  resize: none;
  width: calc(100% - 4px);
  color: currentColor;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
}
textarea:focus {
  outline: none !important;
}

textarea::placeholder {
  color: #bcbcbc;
  font: inherit;
}

.add-button {
  right: 16px;
  bottom: 16px;
  padding: 5px !important;
  position: fixed !important;
  font-size: 60px !important;
  border-radius: 100px !important;
}

.drawer {
  width: 100%;
  top: 0;
  position: fixed !important;
  font-size: 40px !important;
  text-align: center;
  background-color: #f4f4f4;
  z-index: 100;
}
.back-button {
  padding: 5px !important;
}
.version-button {
  bottom: 0px;
  padding: 5px !important;
  position: fixed !important;
  box-shadow: none;
  background-color: transparent;
}
.version-checkout-button {
  bottom: 16px;
  padding: 5px !important;
  left: 1px;
  position: fixed !important;
  box-shadow: none;
  background-color: transparent;
}
.slider-list {
  height: 110px !important;
}
.paging-item {
  display: none !important;
}
.MuiTab-root {
  line-height: 1 !important;
}

.CheckoutReferenceField {
  /* top: calc(100% - 145px) !important; */
  max-height: calc(100% - 525px);
  box-shadow: none;
  cursor: "pointer";
  visibility: visible;
}

.CheckoutSourceField {
  /* top: calc(100% - 145px) !important; */
  --radius: 0;
  --baseFg: #fff;
  --baseBg: #ffff;
  --accentFg: #fff;
  --accentBg: #ffffff;
  max-height: calc(100% - 525px);
  box-shadow: none;
  cursor: "pointer";
  margin-bottom: 5px;
  visibility: visible;
}

.MuiList-padding {
  padding: 0 !important;
}

.MuiSelect-icon {
  font-size: 1.5em !important;
}

.MuiExpansionPanelSummary-content {
  margin: 0 !important;
}
.MuiExpansionPanel-root.Mui-expanded {
  margin: 0 !important;
}
.MuiSelect-select:focus {
  background-color: unset !important;
}

.MuiTabScrollButton-root.Mui-disabled {
  opacity: 1 !important;
}

.MuiSvgIcon-fontSizeSmall {
  background-color: #f4f4f4;
  font-size: 25px !important;
  border-radius: 16px;
  border: 3px solid #f4f4f4;
}

/* .MuiButton-root:hover {
  background-color: inherit !important;
} */
.checkout {
  position: fixed;
  bottom: 4.1%;
  width: calc(100% - 76.2%);
  z-index: 99;
}

.confirm {
  z-index: 99;
  margin-top: 50px;
}

.Demo__suggestion-icon {
  margin-right: 8px;
}

.Demo__search-bar-container {
  position: relative;
  z-index: 1;
  margin-top: 8px;
  margin-bottom: 16px;
}

.Demo__search-input-container {
  position: relative;
  box-shadow: none !important;
  border-radius: 8px !important;
}

.Demo__search-input,
.Demo__search-input:focus,
.Demo__search-input:active {
  border: honeydew;
  display: block;
  width: 100%;
  padding-left: 8px;
  font-size: 16px;
  border-radius: 8px;
  outline: none;
}

.Demo__autocomplete-container {
  position: fixed;
  border-bottom: honeydew;
  border-left: rgb(114, 204, 114);
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  border-radius: 0 0 2px 2px;
  width: calc(100% - 58%);
}

.Demo__suggestion-item {
  padding: 16px;
  text-align: left;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
}

.Demo__suggestion-item--active {
  background-color: #fafafa;
}

.pac-container {
  z-index: 1500;
  width: calc(100% - 830px) !important;
  margin-left: -8px;
}
.pac-container::after {
  display: none;
}
.search-location-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
}

.popover_class > .MuiPopover-paper {
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 5px;
}
.popover_states > .MuiPopover-paper {
  padding: 8px;
  margin-top: 5px;
}

.collection > .MuiMenu-paper {
  /* top: calc(100% - 505px) !important; */
  width: calc(100% - 80%);
  box-shadow: none;
  cursor: "pointer";
}
.source > .MuiMenu-paper {
  width: calc(100% - 80%);
  /* top: calc(100% - 145px) !important; */
  max-height: calc(100% - 525px);
  box-shadow: none;
  cursor: "pointer";
}

.time > .MuiMenu-paper {
  width: calc(100% - 80%);
  /* top: calc(100% - 331px) !important; */
  max-height: calc(100% - 370px);
  margin-left: 10px;
  box-shadow: none;
  cursor: "pointer";
}

.select-menu-items > .MuiMenu-paper {
  width: calc(100% - 75%);
  box-shadow: none;
  min-height: 10px;
  padding-top: 0;
  padding-bottom: 0;
  cursor: "pointer";
}

.action-select {
  --radius: 0;
  --baseFg: #fff;
  --baseBg: #ffff;
  --accentFg: #fff;
  --accentBg: #ffffff;
}
select {
  display: block;
  font: inherit;
  font-size: 1rem;
  height: 35px;
  line-height: 1.42857143;
  border: 1px solid var(--baseFg);
  outline: 0;
  border-radius: var(--radius);
  background-color: var(--baseBg);
  /* color: inherit; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("./assets/select.png");
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 1.65em auto, 100%;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.action-select option {
  font-size: 10px;
  border-bottom-color: #000;
  margin-top: 1px;
  margin-bottom: 1px;
  vertical-align: middle;
}

select option:hover {
  box-shadow: 0 0 10px 100px #bcbcbc inset;
  background-color: var(--accentFg);
}

select:active {
  background-image: linear-gradient(var(--accentFg), var(--accentFg)),
    linear-gradient(-135deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(-225deg, transparent 50%, var(--accentFg) 50%),
    linear-gradient(var(--accentFg) 42%, var(--accentBg) 42%);
  color: var(--accentBg);
  border-color: var(--accentFg);
  background-color: var(--accentFg);
}

.product-group-option-add-sub > .MuiButtonBase-root:hover {
  background-color: inherit !important;
}

.complex > input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom checkbox appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1.5px solid #bbbbbb;
  border-radius: 50px;
  background-color: #e7e6e7;
  margin-left: 10px;
  margin-right: 10px;
}

.complex > input[type="checkbox"]:checked {
  background-color: #008000;
}

.complex > input[type="checkbox"]:focus {
  outline: none !important;
}

.Demo__search-input-container > .MuiFormControl-marginNormal {
  margin-top: 8px;
}

.action-select > .MuiListItemText-multiline {
  display: inline-flex;
  width: 100%;
}
.action-select > .MuiListItemText-multiline > .MuiTypography-body1 {
  width: 90%;
}
.MuiOutlinedInput-root {
  border-radius: 10px;
}

.MuiDrawer-paperAnchorRight {
  background-color: #000;
  color: #fff;
}

.segment-data {
  text-align: -webkit-right;
}

.select-field {
  --radius: 0;
  --baseFg: #fff;
  --baseBg: #ffff;
  --accentFg: #fff;
  --accentBg: #ffffff;
  max-height: calc(100% - 525px);
  box-shadow: none;
  cursor: pointer;
  margin-bottom: 20px;
  visibility: visible;
  padding-left: 8px;

  border-radius: 8px;
}